import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { borderRadius, fontSize } from "@mui/system";

function Ads() {

    const authenticated = useSelector(state => state.login)

    const [ads, setAds] = useState([])


    useEffect(() => {

        getAds()

    }, []);


    const getAds = async () => {


        try {
            const response = await axiosInstance.get('/users/' + authenticated.user.id + '/ads');

            setAds(response.data.data)

        } catch (error) {


        }



    }


    return (
        <div className="App">
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px",margin:"0px" }}>İlanların</h2>
                                <Link to="/ilan-ver" style={{textDecoration:"none",backgroundColor:"#0b3d91",color:"white",padding:"10px",borderRadius:"10px"}}>Temizlikçi Ara</Link>
                            </div>
                            
                            {
                                ads.length > 0 &&
                                ads.map((ad, index) => {
                                    return (
                                        <div style={{ marginTop: "20px", marginBottom: "50px", border: "1px solid #d9d9d9", padding: "10px" }}>
                                            <div style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                                <span>İlan No: #{ad.id}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px", alignItems: "center" }}>
                                                {
                                                    new Date().toISOString().split('T')[0] > new Date(ad.date).toISOString().split('T')[0] &&
                                                    <span style={{ fontSize: "12px", marginRight: "10px", backgroundColor: "red", padding: "5px", borderRadius: "10px", color: "white" }}>Yayınlanmıyor</span>
                                                }
                                                <span style={{ fontSize: "12px", marginRight: "10px", backgroundColor: "#AFFC41", padding: "5px", borderRadius: "10px" }}>{ad.date.split("T")[0]}</span><span style={{ fontSize: "12px", backgroundColor: "#B2FF9E", padding: "5px", borderRadius: "10px" }}>{ad.name}/{ad.districtname}</span>
                                            </div>
                                            <div>
                                                <h2 style={{textAlign:"start",fontSize:"16px",fontWeight:"bold",color:"#3b3b3b"}}>{ad.title}</h2>
                                                <p style={{fontSize:"12px",color:"#6b6b6b",textAlign:"start"}}>{ad.description}</p>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                <Link style={{ textDecoration: "none", backgroundColor: "#0B3D91", color: "white", padding: "10px", borderRadius: "10px", fontSize: "14px" }} to={"/ilanlar/" + ad.id}>Başvuruları Görüntüle</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                ads.length == 0 &&
                                <div>
                                    <p>Malesef daha ilan vermiş gözükmüyorsun :(</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ads;
