
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { Helmet } from 'react-helmet-async';
import axiosInstance from '../../Axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { Button, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


function CreateAd() {

    const authenticated = useSelector(state => state.login)
    const history = useHistory();

    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [selectedCity, setSelectedCity] = useState(0)
    const [selectedDis, setSelectedDis] = useState(0)

    const [ad, setAd] = useState({
        title: "",
        desc: "",
        date: null,
        city: 0,
        district: 0,
        checked: "1"
    })

    useEffect(() => {
        if (localStorage.getItem("newad") != undefined && localStorage.getItem("newad") != undefined) {
            const adcopy = JSON.parse(localStorage.getItem("newad"))
            setAd(adcopy)
        }

        getCities()

    }, [])

    useEffect(() => {
        getDistricts()

    }, [ad.city])

    const getCities = async () => {


        try {
            const response = await axiosInstance.get('/cities');

            setCities(response.data)

        } catch (error) {


        }



    }

    const getDistricts = async () => {


        try {
            const response = await axiosInstance.get('/cities/' + ad.city + "/districts");

            setDistricts(response.data)

        } catch (error) {


        }

    }

    useEffect(() => {
        const adCopy = JSON.stringify(ad)
        localStorage.setItem("newad", adCopy)
    }, [ad])


    const addAd = async () => {

        if (authenticated.isloggedin) {

            try {

                const response = await axiosInstance.post('/ads/', ad);

                localStorage.removeItem("newad")

                toast.success("İlanınız oluşturuldu. Başvuralarım ekranına yönlendiriliyorsunuz.")

                setTimeout(() => {
                    history.push("/ilanlar")
                }, 3000)

            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

        }
        else {
            history.push("/login")
        }

    };



    return (
        <div style={{ height: "100vh", overflow: "auto", backgroundColor: "#f5f5f5" }}>
            <Helmet>
                <title>İlan Ver | Temizlikçi Bul</title>
                <meta name='description' content='Türkiyenin Ücretsiz temizlik uygulaması. Ücretsiz bir şekilde ilan ver aradığın hizmeti veren temizlikçilere ulaş.' />
                <meta property="og:title" content="Temizlikçi Bul"></meta>
                <meta property="og:description" content="Türkiyenin Ücretsiz temizlik uygulaması. Ücretsiz bir şekilde ilan ver aradığın hizmeti veren temizlikçilere ulaş."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="twitter:title" content="Temizlikçi Bul"></meta>
                <meta name="twitter:description" content="Türkiyenin Ücretsiz temizlik uygulaması. Ücretsiz bir şekilde ilan ver aradığın hizmeti veren temizlikçilere ulaş."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="keywords" content="temizlik, temizlikçi, ilan, temizlikçi ilanı, temizlik ilanı, temizlik işi" />
            </Helmet>

            <div className='container-fluid' style={{ marginTop: "20px", marginBottom: "10px", height: "auto", overflow: "auto" }} >
                <div className='row justify-content-center' style={{ marginBottom: "10px" }}>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                        <Link to="/" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px", textDecoration: "none", color: "black" }}><img src="icon.png" style={{ width: "40px", height: "40px", borderRadius: "40px" }}></img><h2 style={{ margin: "0px", marginLeft: "10px" }}>RANDEVU DEFTERİM</h2></Link>
                    </div>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: isMobile ? "100%" : "500px", height: "auto", backgroundColor: "white", border: "1px solid #d9d9d9", borderRadius: "10px", padding: "10px" }}>
                            <h1 style={{ marginTop: "20px", fontSize: "18px", textAlign: "start" }}>Temizlik İlanı Ver</h1>
                            <div style={{ textAlign: "start", marginTop: "20px" }}>
                                <input value={ad.title} onChange={(e) => setAd({ ...ad, title: e.target.value })} type='text' placeholder='İlan Başlığı' style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", borderRadius: "5px", padding: "5px", outline: "none" }}></input>
                            </div>
                            <div style={{ textAlign: "start", marginTop: "20px" }}>
                                <textarea value={ad.desc} onChange={(e) => setAd({ ...ad, desc: e.target.value })} type="text" placeholder='Nasıl bir temizlik bekliyorsun?' style={{ width: "100%", height: "200px", border: "1px solid #d9d9d9", borderRadius: "5px", padding: "5px", outline: "none" }}></textarea>
                            </div>
                            <div style={{ textAlign: "start", marginTop: "20px" }}>
                                <p>Hangi Tarihte Temizliğe İhtiyacın var ?</p>
                                <input value={ad.date} onChange={(e) => setAd({ ...ad, date: e.target.value })} type='date' placeholder='Hangi tarihte temizliğe ihtiyacın var?' style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", borderRadius: "5px", padding: "5px", outline: "none" }} />
                            </div>
                            <div style={{ textAlign: "start", marginTop: "20px" }}>
                                <p>Hangi şehirde temizliğe ihtiyacın var?</p>
                                <select value={ad.city} onChange={(e) => setAd({ ...ad, city: e.target.value })} style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", borderRadius: "5px", padding: "5px", outline: "none" }}>
                                    <option value={0}>Şehir Seç</option>
                                    {
                                        cities.map((city, index) => {
                                            return (
                                                <option value={city.id}>{city.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {
                                ad.city != 0 &&
                                <div style={{ textAlign: "start", marginTop: "20px" }}>
                                    <p>Hangi ilçede temizliğe ihtiyacın var?</p>
                                    <select value={ad.district} onChange={(e) => setAd({ ...ad, district: e.target.value })} style={{ width: "100%", height: "40px", border: "1px solid #d9d9d9", borderRadius: "5px", padding: "5px", outline: "none" }}>
                                        <option value={0}>İlçe Seç</option>
                                        {
                                            districts.map((dis, index) => {
                                                return (
                                                    <option value={dis.id} >{dis.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "20px" }}>
                                <input type='checkbox' checked={ad.checked == "1" ? true : false} onChange={(e) => setAd({ ...ad, checked: e.target.checked ? "1" : "0" })} /><span style={{ marginLeft: "10px" }}>Verilerimin bana ulaşılması için kullanılmasına onay veriyorum.</span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "20px" }}>
                                <button style={{ border: "none", backgroundColor: "#0B3D91", color: "white", padding: "10px", borderRadius: "10px" }} onClick={() => addAd()}>İlanı Ver</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CreateAd;
