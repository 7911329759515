import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";
import { login } from "../../Redux/Actions/login";
import md5 from "md5";

function Profile() {

  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.login)
  const [phone, setPhone] = useState(authenticated.user.phone)
  const [name, setName] = useState(authenticated.user.name)
  const [mail, setMail] = useState(authenticated.user.mail)
  const [birth, setBirth] = useState(authenticated.user.birthdate != null ? authenticated.user.birthdate.split("T")[0] : "1900-10-22")
  const [disable, setDisable] = useState(false)
  const [image, setImage] = useState("default.png")
  const [description,setDescription] = useState("")

  const [oldpassword, setOldPassword] = useState("")
  const [newpassword, setnewpassword] = useState("")
  const [newpasswordrep, setnewpasswordrep] = useState("")

  useEffect(() => {
    const getUser = async () => {
      setDisable(true)
      try {
        const response = await axiosInstance.get('/users/' + authenticated.user.id);

        setPhone(response.data.user.phone)
        setName(response.data.user.name)
        setMail(response.data.user.mail)
        setBirth(response.data.user.birthdate.split("T")[0])
        setImage(response.data.user.image != null ? response.data.user.image : "default.png")
        console.log(description)
        setDescription(response.data.user.description)
        setTimeout(() => {
          setDisable(false)
        }, 2000)

      } catch (error) {

        if (error.response != undefined)
          toast.error(error.response.data.error)
        else {
          toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
        }

      }

    }

    getUser()

  }, [])

  



  const saveImage = async () => {
    setDisable(true)
    try {

      const response = await axiosInstance.put('/users/' + authenticated.user.id + "/image",
        {
          image
        }
      );

      setPhone(response.data.user.phone)
      setName(response.data.user.name)
      setMail(response.data.user.mail)
      setBirth(response.data.user.birthdate.split("T")[0])

      localStorage.setItem("user", JSON.stringify(response.data.user))
      dispatch(login(response.data.user, authenticated.token))

      toast.success("Profil fotoğrafınız başarıyla güncellenmiştir.")






    } catch (error) {

      if (error.response != undefined)
        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

    setTimeout(() => {
      setDisable(false)
    }, 2000)


  }

  const saveProfile = async () => {
    setDisable(true)
    try {
      const response = await axiosInstance.put('/users/' + authenticated.user.id,
        {
          name,
          phone,
          mail,
          birthdate: new Date(birth),
          description
        }
      );

      setPhone(response.data.user.phone)
      setName(response.data.user.name)
      setMail(response.data.user.mail)
      setBirth(response.data.user.birthdate.split("T")[0])
      setDescription(response.data.user.description)

      localStorage.setItem("user", JSON.stringify(response.data.user))
      dispatch(login(response.data.user, authenticated.token))

      toast.success("Profiliniz güncellenmiştir.")


    } catch (error) {

      if (error.response != undefined)
        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

    setTimeout(() => {
      setDisable(false)
    }, 2000)

  }

  const savePassword = async () => {

    setDisable(true)

    if ((oldpassword.length >= 5 && oldpassword.length <= 100) && (newpassword.length >= 5 && newpassword.length <= 100) && (newpasswordrep.length >= 5 && newpasswordrep.length <= 100)) {

      try {

        const response = await axiosInstance.put('/users/' + authenticated.user.id + "/password",
          {
            oldpassword: md5(oldpassword),
            newpassword: md5(newpassword),
            newpasswordrep: md5(newpasswordrep)
          }
        );

        setPhone(response.data.user.phone)
        setName(response.data.user.name)
        setMail(response.data.user.mail)
        setBirth(response.data.user.birthdate.split("T")[0])

        localStorage.setItem("user", JSON.stringify(response.data.user))
        dispatch(login(response.data.user, authenticated.token))

        toast.success("Şifreniz başarıyla güncellenmiştir.")





      } catch (error) {

        if (error.response != undefined)
          toast.error(error.response.data.error)
        else {
          toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
        }

      }

    }
    else {
      toast.error("Şifre alanı en az 5 karakter en çok 100 karakter olmalıdır.")
    }

    setTimeout(() => {
      setDisable(false)
    }, 2000)


  }


  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
          <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
            <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
              <img src={image} style={{ width: "200px", height: "200px", borderRadius: "100px", border: "1px solid #d9d9d9" }} />
              <input
                style={{ border: "1px solid red", position: "absolute", top: "0px", left: "0px", opacity: "0", zIndex: "10000", width: "100%", height: "100%", cursor: "pointer" }}
                type="file"
                id="fileInput"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => {

                  const file = e.target.files[0];
                  const maxSizeInBytes = 10 * 1024 * 1024;
                  if (file && file.size <= maxSizeInBytes) {


                    const reader = new FileReader();

                    reader.onload = () => {
                      const base64String = reader.result;
                      setImage(base64String);
                    };

                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }
                  else {
                    toast.error("Lütfen profil fotoğrafını 10MB'tan düşük seçiniz")
                  }

                }}
              />
              <h2 style={{ textAlign: "center", color: "#969696", fontSize: "12px", marginBottom: "20px" }}>Profil fotoğrafının üzerine tıklayarak seçebilirsin</h2>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", zIndex: "10000000000000000", position: "absolute", bottom: "20px", right: "20px" }}>
                <button disabled={disable} style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", padding: "10px", color: "white" }} onClick={(e) => saveImage(e)}>Güncelle</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center" style={{ marginBottom: "10px" }}>



          <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
            <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px" }}>
              <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Profil Bilgileri</h2>
              <h4 style={{ textAlign: "start", color: "#969696", fontSize: "12px", marginBottom: "20px" }}>Özgeçmiş</h4>
              <textarea style={{width:"100%",height:"500px",border:"1px solid #d9d9d9",borderRadius:"10px",padding:"10px",outline:"none"}} value={description} onChange={(e)=>setDescription(e.target.value)} placeholder="Ne kadar tecrübelisiniz? Kendinizi tanıtın..."></textarea>

              <input style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={name} onChange={(e) => {
                setName(e.target.value)
              }} />
              <input style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={phone} onChange={(e) => {
                setPhone(e.target.value)
              }} />
              <input style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={mail} onChange={(e) => {
                setMail(e.target.value)
              }} />
              <input type={"date"} style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={birth} onChange={(e) => {
                setBirth(e.target.value)
              }} />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                <button disabled={disable} style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", padding: "10px", color: "white" }} onClick={() => saveProfile()} >Güncelle</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">

          <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
            <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px" }}>
              <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Şifre Güncelle</h2>
              <input placeholder={"Eski Şifreniz"} type="password" style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={oldpassword} onChange={(e) => {
                setOldPassword(e.target.value)
              }} />
              <input placeholder={"Yeni Şifreniz"} type="password" style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={newpassword} onChange={(e) => {
                setnewpassword(e.target.value)
              }} />
              <input placeholder={"Yeni Şifre Tekrar"} type="password" style={{ padding: "10px", border: "1px solid #d9d9d9", borderRadius: "10px", height: "40px", outline: "none", width: "100%", display: "block", marginBottom: "20px" }} value={newpasswordrep} onChange={(e) => {
                setnewpasswordrep(e.target.value)
              }} />

              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                <button disabled={disable} style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", padding: "10px", color: "white" }} onClick={() => savePassword()} >Güncelle</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Profile;
