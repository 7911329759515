
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { Helmet } from 'react-helmet-async';
import axiosInstance from '../../Axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { Button, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


function Home() {
  const history = useHistory();

  const authenticated = useSelector(state => state.login)

  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState(0)


  const [districts, setDistricts] = useState([])
  const [selectedDis, setSelectedDis] = useState(0)


  const [selectedType, setSelectedType] = useState(1)


  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)


  const [pop, setPop] = useState(false)

  const [ads, setAds] = useState([])


  const [price, setPrice] = useState(0)
  const [note, setNote] = useState("")

  const [selectedAd, setSelectedAd] = useState({ date: "" });

  const [comments, setComments] = useState([])

  useEffect(() => {

    getCities()

  }, []);


  useEffect(() => {
    setSelectedDis(0)
    getDistricts()
    getAds()

  }, [selectedCity])


  useEffect(() => {
    getAds()
    setSelectedDis(selectedDis)

  }, [selectedDis])

  const getCities = async () => {


    try {
      const response = await axiosInstance.get('/cities');

      setCities(response.data)

    } catch (error) {


    }



  }


  const getDistricts = async () => {


    try {
      const response = await axiosInstance.get('/cities/' + selectedCity + "/districts");

      setDistricts(response.data)

    } catch (error) {


    }



  }


  const getAds = async () => {

    try {
      const response = await axiosInstance.get('/ads?city=' + selectedCity + '&district=' + selectedDis + '&type=' + selectedType + '&page=' + page);

      setAds(response.data.result.data)
      setTotal(response.data.result.total)


    } catch (error) {


    }
  }



  const handleChange = (event, value) => {
    setPage(value);
  };


  useEffect(() => {
    getAds()
  }, [page])


  const createAppointment = (ad) => {

    if (authenticated.isloggedin) {

      setPop(true)
      setSelectedAd(ad)

    }
    else {
      history.push('/register');
    }
  };


  const sendApplication = async () => {

    try {

      const response = await axiosInstance.post('/ads/' + selectedAd.id + '/applications', {
        price: parseInt(price),
        note
      }
      );

      getAds()
      setPrice(0)
      setNote("")
      setPop(false)

      toast.success("Randevu teklifiniz gönderildi. Eğer teklifiniz kabul edilirse ilanı veren kişi sizinle telefonunuz veya mail adresiniz üzerinden iletişime geçecek.")


    } catch (error) {

      if (error.response != undefined)
        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  };







  return (
    <div style={{ height: "auto", overflow: "hidden" }}>
      <Helmet>
        <title>Temizlikçi Bul | Temizlik işi bul</title>
        <meta name='description' content='Türkiyenin Ücretsiz temizlik uygulaması. Ücretsiz bir şekilde ilan ver aradığın hizmeti veren temizlikçilere ulaş.' />
        <meta property="og:title" content="Temizlikçi Bul"></meta>
        <meta property="og:description" content="Türkiyenin Ücretsiz temizlik uygulaması. Ücretsiz bir şekilde ilan ver aradığın hizmeti veren temizlikçilere ulaş."></meta>
        <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
        <meta name="twitter:title" content="Temizlikçi Bul"></meta>
        <meta name="twitter:description" content="Türkiyenin Ücretsiz temizlik uygulaması. Ücretsiz bir şekilde ilan ver aradığın hizmeti veren temizlikçilere ulaş."></meta>
        <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
        <meta name="keywords" content="temizlik, temizlikçi, ilan, temizlikçi ilanı, temizlik ilanı, temizlik işi" />
      </Helmet>
      <MainMenu type={2} />

      <div style={{ position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: 10000, display: "flex", alignItems: "center", justifyContent: "center", visibility: pop ? "visible" : "hidden" }}>
        <div style={{ backgroundColor: "black", opacity: "0.5", position: "fixed", left: "0px", top: "0px", width: "100%", height: "100%" }} onClick={() => setPop(false)}></div>
        <div style={{ width: "350px", height: "auto", backgroundColor: "white", zIndex: 10000000, padding: "30px", borderRadius: "10px" }}>
          <div style={{ marginBottom: "20px" }}>
            <h4>Randevu Oluştur</h4>
            <span>{selectedAd.username}</span>
            <br />
            <span>{selectedAd.name}/{selectedAd.districtname}</span>
            <br />
            <span>Şu tarihte lazım: {selectedAd.date.split("T")[0]}</span>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "start", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
              <h4 style={{ fontSize: "16px" }}>Fiyat Teklifiniz Nedir? (TL)</h4>
              <input onChange={(e) => setPrice(e.target.value)} value={price} type='number' placeholder='Fiyat' style={{ height: "50px", border: "1px solid #d9d9d9", borderRadius: "10px", width: "300px", outline: "none", padding: "10px" }}></input>
            </div>
            <div style={{ display: "flex", alignItems: "start", justifyContent: "center", flexDirection: "column" }}>
              <h4 style={{ fontSize: "16px" }}>Notunuz</h4>
              <textarea onChange={(e) => setNote(e.target.value)} value={note} placeholder='Hizmet vereni ikna için mesajınızı yazın...' style={{ height: "150px", border: "1px solid #d9d9d9", borderRadius: "10px", width: "300px", outline: "none", padding: "10px" }}></textarea>
            </div>
            <div style={{ marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <Button variant="contained" style={{ backgroundColor: "#0b3d91", color: "white" }} onClick={() => { sendApplication() }}>
                Teklifi Yolla
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px", height: "auto", overflow: "auto" }} id="ilanlar">
        <div className='row justify-content-center' style={{ marginBottom: "40px" }}>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <h4>Hangi bölgede temizlik işi arıyorsun?</h4>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-3' style={{ marginTop: "10px", padding: "0px" }}>
                  <div style={{ borderRadius: "10px" }}>

                    <select style={{ width: "100%", height: "50px", border: "1px solid #d9d9d9", outline: "none", borderRadius: "10px" }} onChange={(e) => setSelectedCity(e.target.value)} value={selectedCity}>
                      <option value={0}>Şehir Seç</option>
                      {
                        cities.map((city, index) => {
                          return (
                            <option value={city.id}>{city.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-3' style={{ marginTop: "10px", padding: "0px", marginLeft: isMobile ? "0px" : "10px" }}>
                  <div style={{ borderRadius: "10px", padding: "0px" }}>


                    <select style={{ width: "100%", height: "50px", border: "1px solid #d9d9d9", outline: "none", borderRadius: "10px" }} onChange={(e) => setSelectedDis(e.target.value)} value={selectedDis}>
                      <option value={0}>İlçe Seç</option>
                      {
                        districts.map((dis, index) => {
                          return (
                            <option value={dis.id} >{dis.name}</option>
                          )
                        })
                      }
                    </select>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <h2>Temizlik İlanları</h2>
            </div>
          </div>
          {
            ads.length > 0 &&
            ads.map((ad, index) => {
              return (
                <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", width: "100%", padding: "10px", backgroundColor: ad.isApplied ? "#e8e8e8" : "white" }}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <span style={{ fontSize: "12px", color: "black", marginRight: "10px", backgroundColor: ad.isApplied ? "#ccc" : "#AFFC41", padding: "5px", borderRadius: "10px" }}>Şu Tarihte Lazım : {ad.date.split("T")[0]}</span><span style={{ fontSize: "14px", backgroundColor: ad.isApplied ? "#ccc" : "#B2FF9E", padding: "5px", color: "black", borderRadius: "10px" }}>{ad.name}/{ad.districtname}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "20px" }}>
                      <img src={ad.userimage != null ? ad.userimage : "default.png"} style={{ width: "40px", height: "40px", borderRadius: "40px" }} />
                      <h2 style={{ margin: "0px", marginLeft: "10px", fontSize: "14px", fontWeight: "bold" }}>{ad.username}</h2>
                    </div>
                    <h2 style={{ fontSize: "16px", fontWeight: "bold", color: "#3b3b3b" }}>{ad.title}</h2>
                    <p style={{ fontSize: "12px", color: "#6b6b6b" }}>{ad.description}</p>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button variant="contained" style={{ backgroundColor: ad.isApplied ? "#ccc" : "#0b3d91", color: "white" }} onClick={() => { createAppointment(ad) }} disabled={ad.isApplied == 1 ? true : false}>
                        {ad.isApplied == 1 ? "Teklif Gönderildi" : "Teklif Ver"}
                      </Button>
                    </div>
                  </div>
                </div>
              )
            })
          }
          {
            ads.length == 0 &&
            <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
              <h2>İlan bulunamadı :( </h2>
            </div>
          }

        </div>

      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination count={Math.ceil(total / 5)} color="primary" page={page} onChange={handleChange} />
      </div>

      <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "50px", height: "auto", overflow: "auto" }} id="ilanlar">
        <div className='row justify-content-center' style={{ marginBottom: "40px", padding: "10px" }}>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <h2 style={{ textAlign: "center" }}>NASIL ÇALIŞIR?</h2>
          </div>

        </div>
        <div className='row justify-content-center' style={{ marginBottom: "40px", backgroundColor: "#f5f5f5", padding: "30px" }}>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
            <img src="picture1.png" style={{ width: "100%", height: "100%", borderRadius: "10px" }}></img>
          </div>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div>
              <h2>Temizlik İhtiyacını anlat</h2>
              <p>Nasıl bir temizlik istediğini ilan vererek anlat. İlanın yayınlansın.</p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center' style={{ marginBottom: "40px", backgroundColor: "#b2ff9e", padding: "30px" }}>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div>
              <h2>Temizlikçiler İlanlarına Teklif Versin</h2>
              <p>Temizlikçiler açtığın temizlik ilanına kendine uygun olan fiyat ile teklif versin</p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
            <img src="picture4.png" style={{ width: "100%", height: "100%", borderRadius: "10px" }}></img>
          </div>
        </div>
        <div className='row justify-content-center' style={{ marginBottom: "40px", backgroundColor: "#0b3d91", padding: "30px" }}>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
            <img src="picture3.png" style={{ width: "100%", height: "100%", borderRadius: "10px" }}></img>
          </div>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ color: "white" }}>
              <h2>Sana uygun olan teklifi seç</h2>
              <p>Kendine uygun temizlikçiyi başvurular arasından seç ve kaliteli hizmet al.</p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center' style={{ marginBottom: "40px", padding: "30px" }}>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div>
              <h2>Aldığın hizmeti puanla!</h2>
              <p>Aldığın hizmeti puanla temizlikçiler için başka iş imkanları yarat!</p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
            <img src="picture5.png" style={{ width: "100%", height: "100%", borderRadius: "10px" }}></img>
          </div>
        </div>
      </div>


      <div className='container-fluid' style={{ backgroundColor: "white", marginTop: "50px", height: "auto", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ paddingTop: "100px" }}>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "start", flexDirection: "column" }}>
                    <Link style={{ textDecoration: "none", color: "black", fontSize: "44px", fontWeight: "bold", display: "block", width: "100%" }} to="/register">Çok Yakında Uygulamlar Hazır!</Link>
                    <div style={{ textAlign: "start" }}>

                      <img src='google.png' style={{ width: "200px" }} />


                      <img src='apple.png' style={{ width: "200px" }} />

                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "50px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "start", textAlign: "start" }}>
                    <div style={{}}>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/blog">Blog</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-takip-programi">Randevu Takip Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/dis-hekimi-randevu-programi">Diş Hekimi Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-salonu-randevu-programi">Güzellik Salonu Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/diyetisyen-randevu-programi">Diyetisyen Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/berber-randevu-programi">Berber Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-merkezi-randevu-programi">Güzellik Merkezi Randevu Programı</Link>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "50px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                    <a style={{ textDecoration: "none", color: "black", fontSize: "18px", display: "block", textAlign: "start", width: "100%" }} href="https://www.instagram.com/mikropuzmani/">
                      Bizi Takip Edin! <i class="ri-instagram-line"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
