
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { useState } from 'react';
import axiosInstance from '../../Axios';
import { login } from '../../Redux/Actions/login';
import md5 from "md5";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
function Resetpassword() {

    const dispatch = useDispatch();

    const [phone, setPhone] = useState("")
    const [newpassword, setNewPassword] = useState("")
    const [newpasswordrep, setNewPasswordRep] = useState("")
    const [passwordMessage, setPasswordMessage] = useState("")
    const [passwordMessagerep, setPasswordMessageRep] = useState("")

    const [loading, setLoading] = useState(false)

    const [loginMessage, setLoginMessage] = useState("")

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('tkn');


    const resetPassword = async () => {

        if (newpassword.length > 4 && newpasswordrep.length>4) {

            setLoading(true);

            try {
                const response = await axiosInstance.post('/login/resetpassword',
                    {
                        newpassword:md5(newpassword),
                        newpasswordrep: md5(newpasswordrep),
                        token
                    });

                    toast.success("Şifreniz yenilenmiştir.")

                setTimeout(() => {
                    setLoading(false)
                    window.location.href = "/"
                }, 2000)


            } catch (error) {

                setTimeout(() => {
                    setLoginMessage(error.response != undefined ? error.response.data.error : "Bağlantı problemi yaşıyoruz. Lütfen daha sonra tekrar deneyiniz.")
                    setLoading(false);
                }, 2000)

            }
        }
        else {
            setPasswordMessage("Şifre alanı 4 karakterden çok olmalıdır.")
        }

    }

    return (
        <div className="App">
            <MainMenu type={2} />
            <div>
                <div className='container-fluid' >
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-3' style={{ padding: "0px", zIndex: "10" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                                <div style={{ border: "1px solid #D9D9D9", borderRadius: "10px", height: "554px", width: "100%", padding: "20px", marginBottom: "30px" }}>
                                    {
                                        loginMessage.length > 0 &&
                                        <div class="alert alert-danger" role="alert">
                                            {loginMessage}
                                        </div>
                                    }
                                    <h1 style={{ marginTop: "50px", marginBottom: "20px" }}>Şifreni sıfırla</h1>

                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{passwordMessage}</p>
                                    <input disabled={loading} value={newpassword} onChange={(e) => {
                                        if (e.target.value.length < 5 || e.target.value.length > 35) {
                                            setNewPassword(e.target.value)
                                            setPasswordMessage("Şifre 4 karakterden çok olmalı")
                                        }
                                        else {
                                            setNewPassword(e.target.value)
                                            setPasswordMessage("")
                                        }
                                    }} type='password' style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px" }} placeholder='Yeni Şifre' />

                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{passwordMessagerep}</p>
                                    <input disabled={loading} value={newpasswordrep} onChange={(e) => {
                                        if (e.target.value.length < 5 || e.target.value.length > 35) {
                                            setNewPasswordRep(e.target.value)
                                            setPasswordMessageRep("Şifre 4 karakterden çok olmalı")
                                        }
                                        else {
                                            setNewPasswordRep(e.target.value)
                                            setPasswordMessageRep("")
                                        }
                                    }} type='password' style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px" }} placeholder='Yeni Şifre Tekrar' />
                                    <button disabled={loading} style={{ width: "100%", height: "51px", marginTop: "20px", borderRadius: "10px", outline: "none", backgroundColor: "#0b3d91", border: "none", color: "white" }} onClick={() => resetPassword()}>{loading ? <div class="spinner-border text-light" role="status"></div> : "Şifreyi Sıfırla"}</button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Resetpassword;
