
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Profile from './Profile';
import { useEffect } from 'react';
import { login } from '../../Redux/Actions/login';
import Panel from '../../Components/Panel';

function Private() {

  const authenticated = useSelector(state => state.login)
  console.log( "hello2")


  if (authenticated.isloggedin) {
    return (
      <div className="App">

        <Switch>
          <Route path="*" component={Panel} />
        </Switch>

      </div>
    );
  }
  else {
    return <Redirect to="/login" />
  }


}

export default Private;
