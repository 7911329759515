import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";
import { login } from "../../Redux/Actions/login";
import md5 from "md5";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Profil() {

    const dispatch = useDispatch();
    const authenticated = useSelector(state => state.login)
    const [phone, setPhone] = useState(authenticated.user.phone)
    const [name, setName] = useState(authenticated.user.name)
    const [mail, setMail] = useState(authenticated.user.mail)
    const [birth, setBirth] = useState(authenticated.user.birthdate != null ? authenticated.user.birthdate.split("T")[0] : "1900-10-22")
    const [disable, setDisable] = useState(false)
    const [image, setImage] = useState("default.png")
    const [description, setDescription] = useState("")

    const [selected, setSelected] = useState(0)
    const [comments, setComments] = useState([])



    useEffect(() => {
        const getUser = async () => {
            setDisable(true)
            try {
                const response = await axiosInstance.get('/users/' + authenticated.user.id);

                setPhone(response.data.user.phone)
                setName(response.data.user.name)
                setMail(response.data.user.mail)
                setBirth(response.data.user.birthdate.split("T")[0])
                setImage(response.data.user.image != null ? response.data.user.image : "default.png")
                setDescription(response.data.user.description)
                setTimeout(() => {
                    setDisable(false)
                }, 2000)

            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

        }

        getUser()

        getComments()

    }, [])



    const getComments = async () => {


        try {
            const response = await axiosInstance.get('/users/' + authenticated.user.id + '/comments');

            console.log(response.data)
            setComments(response.data.data)

        } catch (error) {


        }

    }


    const ortalamaPuan = () => {
        if (comments.length > 0) {
            var i;
            var total = 0;
            for (i = 0; i < comments.length; i++) {
                total = total + parseInt(comments[i].point);
                console.log(comments[i].point)
            }

            return total / comments.length
        }
    }




    return (
        <div className="App">
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <Link to="/profil-guncelle" style={{ textDecoration: "none", color: "white", backgroundColor: "#0b3d91", padding: "10px", borderRadius: "10px" }}>Güncelle</Link>
                            </div>
                            <img src={image} style={{ width: "200px", height: "200px", borderRadius: "100px", border: "1px solid #d9d9d9" }} />

                            <h2 style={{ textAlign: "center", color: "black", fontSize: "18px", marginBottom: "20px" }}>{name}</h2>
                            <h3 style={{ textAlign: "center", color: "#aaa", fontSize: "14px", marginBottom: "20px" }}>Doğum Tarihi: {birth}</h3>
                            <h3 style={{ textAlign: "center", color: "#aaa", fontSize: "14px", marginBottom: "20px" }}>İletişim: {phone} - {mail}</h3>

                        </div>
                    </div>
                </div>
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "10px", marginBottom: "10px" }}>
                            <button style={{ border: "none", color: selected == 0 ? "white" : "black", backgroundColor: selected == 0 ? "#16cdb7" : "transparent", padding: "10px", borderRadius: "10px", fontSize: "14px" }} onClick={() => setSelected(0)}>
                                Özgeçmiş
                            </button>
                            <button style={{ border: "none", color: selected == 1 ? "white" : "black", backgroundColor: selected == 1 ? "#16cdb7" : "transparent", padding: "10px", borderRadius: "10px", fontSize: "14px" }} onClick={() => setSelected(1)}>
                                Yorumlar
                            </button>
                        </div>

                        {
                            selected == 0 &&
                            <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px" }}>
                                <h4 style={{ textAlign: "start", color: "#969696", fontSize: "12px", marginBottom: "20px" }}>Tecrübelerin</h4>

                                {
                                    description.length > 0 &&
                                    <p style={{ width: "100%", minHeight: "500px", border: "1px solid #d9d9d9", borderRadius: "10px", padding: "10px", outline: "none", textAlign: "start" }} >{description}</p>

                                }
                                {
                                    description.length == 0 &&
                                    <div>
                                        <p>Bir özgeçmişin yok. Profilini güncelleyerek özgeçmiş yükleyebilirsin.<br /></p>
                                        <Link to="/profil-guncelle" style={{ textDecoration: "none", color: "black", backgroundColor: "#B2FF9E", padding: "10px", borderRadius: "10px", marginTop: "20px" }}>Güncelle</Link>
                                    </div>
                                }
                            </div>
                        }
                        {
                            selected == 1 &&
                            <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px" }}>
                                <h4 style={{ textAlign: "start", color: "#969696", fontSize: "12px", marginBottom: "20px" }}>Yorumların (Ort:{ortalamaPuan()}⭐)</h4>

                                {
                                    comments.length > 0 &&

                                    comments.map((com, index) => {
                                        return (
                                            <div style={{ border: "1px solid #d9d9d9", textAlign: "start", minHeight: "100px", marginBottom: "10px", padding: "10px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div><img src="/default.png" style={{ width: "30px", height: "30px", borderRadius: "30px", marginRight: "10px" }} /><span style={{ fontWeight: "bold", fontSize: "12px" }}>{com.commentername}</span></div>
                                                    <div>
                                                        {Array.from({ length: com.point }).map((_, index) => (
                                                            <span key={index}>⭐</span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <hr />
                                                <span style={{ color: "#aaa", fontSize: "12px" }}>{com.comment}</span>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    comments.length == 0 &&
                                    <div>
                                        <p>Herhangi bir yorum yapılmamış :(</p>
                                    </div>
                                }
                            </div>
                        }


                    </div>
                </div>

            </div>
        </div>
    );
}

export default Profil;
