import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";
import ReactStars from 'react-stars'

function AdDetails({ match }) {
    const authenticated = useSelector(state => state.login)

    const { id } = match.params;

    const [pop, setPop] = useState(false)
    const [applications, setApplications] = useState([])
    const [selectedApp, setSelectedApp] = useState({})
    const [comments, setComments] = useState([])

    const [point, setPoint] = useState(0)
    const [comment, setComment] = useState("")

    useEffect(() => {

        getApplications()

    }, []);


    const getApplications = async () => {


        try {
            const response = await axiosInstance.get('/users/' + authenticated.user.id + '/ads/' + id + '/applications');

            setApplications(response.data.data)

        } catch (error) {


        }

    }

    const getComments = async () => {


        try {
            const response = await axiosInstance.get('/users/' + selectedApp.userid + '/comments');

            console.log(response.data)
            setComments(response.data.data)

        } catch (error) {


        }

    }

    useEffect(() => {
        getComments()
    }, [selectedApp])


    const addComment = async () => {

        if (point > 0) {

            try {

                const response = await axiosInstance.post('/users/' + selectedApp.userid + '/comments', {
                    point: Math.floor(point),
                    comment: comment
                }
                );

                getComments()
                setComment("")
                setPoint(0)

                toast.success("Yorumunuz başarıyla kaydedildi!")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
                }

            }

        }
        else {
            toast.error("Lütfen puan veriniz!")
        }

    };
    const ratingChanged = (newRating) => {
        setPoint(Math.floor(newRating))
    }

    const ortalamaPuan = ()=>{
        if(comments.length>0){
            var i;
            var total = 0;
            for(i=0;i<comments.length;i++){
                total = total + parseInt(comments[i].point);
                console.log(comments[i].point)
            }

            return (total/comments.length).toFixed(1)
        }
    }



    return (
        <div className="App">
            <div style={{ position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: 10000, display: "flex", alignItems: "center", justifyContent: "center", visibility: pop ? "visible" : "hidden" }}>
                <div style={{ backgroundColor: "black", opacity: "0.5", position: "fixed", left: "0px", top: "0px", width: "100%", height: "100%" }} onClick={() => setPop(false)}></div>
                <div style={{ width: isMobile ? "90%" : "700px", height: "600px", backgroundColor: "white", zIndex: 10000000, padding: "30px", borderRadius: "10px",overflow:"auto" }}>
                    
                        <div>
                            <h2 style={{ textAlign: "start", fontSize: "18px" }}><b>{selectedApp.username}</b> Hakkında</h2>
                            <div style={{ marginBottom: "50px" }}>
                                <p style={{ textAlign: "start", fontSize: "14px", color: "#aaa" }}>{selectedApp.userdesc}</p>
                            </div>
                        </div>
                    

                    <h2 style={{ textAlign: "start", fontSize: "18px", marginBottom: "40px" }}>Yorumlar ve Değerlendirmeler (Ort:{ortalamaPuan()}⭐)</h2>
                    <div style={{ height: "300px", overflow: "auto" }}>
                        {
                            comments.length > 0 &&

                            comments.map((com, index) => {
                                return (
                                    <div style={{ border: "1px solid #d9d9d9", textAlign: "start", minHeight: "100px", marginBottom: "10px", padding: "10px" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div><img src="/default.png" style={{ width: "30px", height: "30px", borderRadius: "30px", marginRight: "10px" }} /><span style={{ fontWeight: "bold", fontSize: "12px" }}>{com.commentername}</span></div>
                                            <div>
                                                {Array.from({ length: com.point }).map((_, index) => (
                                                    <span key={index}>⭐</span>
                                                ))}
                                            </div>
                                        </div>
                                        <hr />
                                        <span style={{ color: "#aaa", fontSize: "12px" }}>{com.comment}</span>
                                    </div>
                                )
                            })
                        }
                        {
                            comments.length == 0 &&
                            <div>
                                <p>Bu kişiye yapılan bir yorum yok :(</p>
                            </div>
                        }
                    </div>

                    <div style={{ height: "200px" }}>
                        <div style={{ display: "flex", alignItems: "start", justifyContent: "flex-start", flexDirection: "column", marginBottom: "10px" }}>
                            <label>Yorum Yap</label>
                            <ReactStars count={5} onChange={ratingChanged} size={24} color2={'#ffd700'} half={true} value={point} />
                            <textarea style={{ border: "2px solid #d9d9d9", width: "100%", height: "100px" }} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <Button variant="contained" style={{ backgroundColor: "#0b3d91" }} onClick={() => addComment()}>Gönder</Button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Başvuranlar</h2>
                            <h3 style={{ textAlign: "end" }}>İlan No: #{id}</h3>
                            {
                                applications.length > 0 &&
                                applications.map((app, index) => {
                                    return (
                                        <div style={{ border: "1px solid #d9d9d9", padding: "10px", marginTop: "20px" }}>

                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{display:"flex",alignItems:isMobile?"start":"center",justifyContent:"flex-start",flexDirection:isMobile?"column":"row"}}>
                                                    <img src={"/default.png"} style={{ width: "40px", height: "40px", borderRadius: "40px", marginRight: "10px" }} /><span style={{ fontWeight: "bold" }}>{app.username}</span>
                                                    <button style={{ border: "none", textDecoration: "underline", color: "black", fontSize: "12px", marginLeft: isMobile?"0px":"10px", padding: "10px", borderRadius: "10px" }} onClick={() => { setSelectedApp(app); setPop(true) }}>(Değerlendirmeleri Gör)</button>
                                                </div>
                                                <span style={{ backgroundColor: "#affc41", fontSize: "10px", padding: "10px", borderRadius: "10px", fontWeight: "bold" }}>Başvuru Tarihi: {app.date.split("T")[0]}</span>

                                            </div>
                                            <div style={{ textAlign: "start",marginBottom:"20px" }}>
                                                <label style={{ extAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px", marginTop: "20px" }}>Başvuran Notu:</label>
                                                <p style={{ fontSize: "14px" }}>{app.note}</p>
                                            </div>
                                            <div style={{ textAlign: "start" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div>
                                                        <span style={{ fontSize: "12px", backgroundColor: "#b2ff9e", padding: "10px", borderRadius: "10px",fontWeight:"bold" }}><i class="ri-phone-line"></i>Ara: {app.phone}</span>
                                                    </div>
                                                    <span style={{ color: "white", backgroundColor: "#0B3D91", padding: "10px", borderRadius: "10px",fontSize:"12px",fontWeight:"bold" }}>Fiyat Teklifi: {app.price} TL</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                applications.length == 0 &&
                                <p>Bu ilana daha başvuran olmadı :(</p>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdDetails;
